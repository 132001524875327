import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import {Box, Grid, Stack, Typography} from '@mui/material';
import {IMachineDTO} from 'api/api';
import {useAuth} from 'auth/auth-provider';
import {localized, localizedInterpolation} from 'i18n/i18n';
import {useDialog} from 'providers/dialog-provider';
import React, {FC, useCallback} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useDeleteMachineMutation} from 'state/features/api/machines-slice';
import {selectCurrentUser, useRemoveMachineFromUserMutation} from 'state/features/api/user-slice';
import {routes} from 'state/routes';
import {useGoBackOnSuccess} from 'utilities/navigation-helper';
import {BlobImage} from 'view/components/default-components/blob-image';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FlexRow} from 'view/components/default-components/flex-row';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import MachineConnection from 'view/components/specific-components/machine/current-state/machine-connection';
import MachineState from 'view/components/specific-components/machine/current-state/machine-state';
import {ResourcesMenu} from './resources-menu';

interface Props {
  machine: IMachineDTO | undefined;
}

const sharedCacheKey = 'statusHeaderMutation';
export const StatusHeader: FC<Props> = React.memo((props) => {
  const {machine} = props;
  const {isGlobalAdmin, isAgent} = useAuth();
  const currentUser = useSelector(selectCurrentUser);
  const navigate = useNavigate();
  const {openDeleteDialog, openConfirmDialog} = useDialog();
  const [deleteMachine, {isLoading, isSuccess, reset}] = useDeleteMachineMutation({fixedCacheKey: sharedCacheKey});
  const [removeMachineFromUser] = useRemoveMachineFromUserMutation({fixedCacheKey: sharedCacheKey});
  const goToEditMachine = useCallback(() => {
    if (!machine) return;
    navigate(`${routes.machines}/${machine.id}/edit`);
  }, [machine, navigate]);

  const handleDeleteConfirm = useCallback(() => {
    if (!machine?.serialNumber) return;
    deleteMachine(machine.serialNumber);
  }, [deleteMachine, machine?.serialNumber]);

  const handleRemoveConfirm = useCallback(() => {
    if (!machine || !currentUser) return;

    removeMachineFromUser({machineId: machine.id, userId: currentUser.id});
  }, [currentUser, machine, removeMachineFromUser]);

  const handleRemove = useCallback(() => {
    openConfirmDialog(localized('RemoveMachineConfirm'), handleRemoveConfirm);
  }, [handleRemoveConfirm, openConfirmDialog]);

  const handleDelete = useCallback(() => {
    openDeleteDialog(localized('ConfirmDeleteMachine'), handleDeleteConfirm);
  }, [handleDeleteConfirm, openDeleteDialog]);

  const navigateBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  useGoBackOnSuccess(isSuccess, reset);

  const {serialNumber, machineTypeImageUrl, machineStateDto, machineTypeName} = machine ?? {};

  return (
    <PageHeader area="machineDetails">
      <Grid container spacing={{xs: 5, xl: 6}} sx={{paddingBottom: 1}}>
        <Grid item xs={12} md>
          <Stack direction="row">
            {machineTypeImageUrl && (
              <BlobImage alt="Machine Image" src={`/images/machines/${machineTypeImageUrl}`} maxWidth={115} />
            )}

            <Stack sx={{paddingLeft: 5}} justifyContent="space-between">
              <Box
                sx={{display: 'flex', alignItems: 'center', marginLeft: '-6px', cursor: 'pointer'}}
                onClick={navigateBack}>
                <KeyboardArrowLeftIcon color="primary"></KeyboardArrowLeftIcon>
                <Typography color="common.flexoWashBlue" marginTop="1.4px" variant="body2">
                  {localized('Back')}
                </Typography>
              </Box>
              <Stack>
                <Typography color="common.flexoWashBlue" variant="h4">
                  {machineTypeName}
                </Typography>
                <Typography variant="body3" color="common.flexoWashBlueLight">
                  #{serialNumber ?? 'Unknown'}
                </Typography>
              </Stack>
              <FlexRow gap={2} mt={2}>
                <ResourcesMenu machine={machine} />
                {isGlobalAdmin && (
                  <>
                    <BasicButton
                      disabled={isLoading}
                      onClick={goToEditMachine}
                      text={localizedInterpolation('EditInt', 'Machine')}
                    />
                    <BasicButton
                      disabled={isLoading}
                      buttonColor="error"
                      onClick={handleDelete}
                      text={localized('DeleteMachine')}
                    />
                  </>
                )}
                {!isGlobalAdmin && !isAgent && (
                  <BasicButton
                    disabled={isLoading}
                    buttonColor="error"
                    onClick={handleRemove}
                    text={localizedInterpolation('RemoveInt', 'Machine')}
                  />
                )}
              </FlexRow>
            </Stack>
          </Stack>
        </Grid>

        <Grid container item xs={12} md sx={{display: 'flex', alignItems: 'flex-end'}}>
          <Grid
            container
            item
            columnGap={[6, 5]}
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
            }}>
            <Grid item xs>
              {machineStateDto?.machineStatus !== undefined && (
                <MachineState variant="machine-details" machineCurrentState={machineStateDto} />
              )}
            </Grid>
            <Grid item xs>
              {machineStateDto?.connectionStatus !== undefined && (
                <MachineConnection
                  iconPlacement="left"
                  variant="machine-details"
                  latestHeartbeat={machineStateDto.latestConnectionStateChange}
                  connectionState={machineStateDto.connectionStatus}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PageHeader>
  );
});
