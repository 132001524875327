import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box} from '@mui/material';
import {AlarmSeverity, MachineAlarmDTO} from 'api/api';
import {FC, memo} from 'react';
import {activeAlarmListBackgroundColor, warningColor} from 'styles/color-constants';
import {theme} from 'styles/theme';

interface PropsFromParent {
  alarm: MachineAlarmDTO;
}
export const ActiveAlarmItem: FC<PropsFromParent> = memo(({alarm}) => {
  return (
    <Box
      sx={{
        backgroundColor: activeAlarmListBackgroundColor,
        padding: 1,
        gap: 2,
        alignItems: 'center',
        display: 'flex',
        marginTop: 1,
      }}>
      <WarningAmberIcon
        sx={{color: alarm.alarmSeverity === AlarmSeverity.Alarm ? theme.palette.error.main : warningColor}}
      />
      <Box fontSize={16}>{alarm.errorDescription}</Box>
    </Box>
  );
});
