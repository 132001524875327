import {Box, Grid, Stack, Typography} from '@mui/material';
import {ConnectionStatus} from 'api/api';
import {localized} from 'i18n/i18n';
import {FC, memo, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useParams} from 'react-router-dom';
import {machineHistoryClient} from 'state/api-clients';
import {selectMachineById, useGetMachinesQuery} from 'state/features/api/machines-slice';
import {useGetTankStatusQuery} from 'state/features/api/tank-status-slice';
import {AsyncOperationHandler} from 'utilities/platform-helpers/api-helper';
import TankStatus from 'view/components/specific-components/tank-status/tank-status';
import {PageContentContainer} from '../../../components/default-components/page-container/page-content-container';
import {PageTitle} from '../../../components/default-components/page-title/page-title';
import {Statistics} from './Statistics/statistics';
import {ActiveAlarmItem} from './active-alarm-item';
import DownloadCSVButton from './download-csv-button';
import {AlarmHistory} from './machine-history/alarm-history/alarm-history';
import {CleaningHistory} from './machine-history/cleaning-history/cleaning-history';
import {StatusHeader} from './status-header/status-header';

export const MachineDetails: FC = memo(() => {
  const {machineId: machineIdString} = useParams();
  const machineId: number = useMemo(() => Number(machineIdString), [machineIdString]);
  useGetMachinesQuery(undefined, {
    pollingInterval: 10000,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const machine = useSelector(selectMachineById(machineId));
  const {data: tankStatus} = useGetTankStatusQuery(machineId);

  const exportCleaningHistory = useCallback(async () => {
    if (machineId) {
      return await AsyncOperationHandler(() => machineHistoryClient.exportCleaningHistory(machineId));
    }
  }, [machineId]);

  const exportAlarmHistory = useCallback(async () => {
    if (machineId) {
      return await AsyncOperationHandler(() => machineHistoryClient.exportAlarmHistory(machineId));
    }
  }, [machineId]);

  return (
    <PageTitle title="Machines">
      <StatusHeader machine={machine} />

      <PageContentContainer xy>
        <Statistics />

        {(tankStatus?.tank1 || tankStatus?.tank2 || !!machine?.activeAlarms?.length) && (
          <Box marginTop={6} flexDirection={'row'} display={'flex'}>
            {(tankStatus?.tank1 || tankStatus?.tank2) &&
              machine?.machineStateDto?.connectionStatus !== ConnectionStatus.Offline && (
                <Box flex={1}>
                  <Typography marginBottom={3} variant="h3">
                    {localized('TankStatus')}
                  </Typography>
                  <Stack display="inline-flex" flexDirection="row" columnGap={8} py={1} width="100%">
                    <TankStatus tank={tankStatus?.tank1} tankNumber={1} />
                    <TankStatus tank={tankStatus?.tank2} tankNumber={2} />
                  </Stack>
                </Box>
              )}
            {machine?.activeAlarms?.length && machine.machineStateDto?.connectionStatus !== ConnectionStatus.Offline ? (
              <Box flex={3} ml={1}>
                <Typography marginBottom={3} variant="h3" sx={{position: 'sticky'}}>
                  {localized('ActiveAlarms')}
                </Typography>
                <Box overflow={'auto'} maxHeight={220}>
                  {machine?.activeAlarms?.map((alarm) => (
                    <ActiveAlarmItem key={alarm.alarmIndex} alarm={alarm} />
                  ))}
                </Box>
              </Box>
            ) : (
              <></>
            )}
          </Box>
        )}

        <Box marginTop={6}>
          <Grid
            container
            spacing={{
              xs: 5,
              xl: 6,
            }}>
            <Grid item xs={12} xl>
              <Stack direction="row" alignItems="center" marginBottom="17px">
                <Typography variant="h3">{localized('CleaningHistory')}</Typography>
                <DownloadCSVButton downloadMethod={exportCleaningHistory} />
              </Stack>
              {machineId && <CleaningHistory machineId={machineId} />}
            </Grid>

            <Grid item xs={12} xl>
              <Stack direction="row" alignItems="center" marginBottom="17px">
                <Typography variant="h3">{localized('AlarmHistory')}</Typography>
                <DownloadCSVButton downloadMethod={exportAlarmHistory} />
              </Stack>

              {machineId && <AlarmHistory machineId={machineId} />}
            </Grid>
          </Grid>
        </Box>
      </PageContentContainer>
    </PageTitle>
  );
});
