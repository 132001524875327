import {Check} from '@mui/icons-material';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {Box, Typography} from '@mui/material';
import {
  AlarmSeverity,
  ConnectionStatus,
  IMachineDTO,
  IMachineLatestCleaningDTO,
  IMachineLatestStatisticsDTO,
  IMachineLatestTankStatusDTO,
  IOrganizationDTO,
  TankLevel,
} from 'api/api';
import {localized, localizedDynamic} from 'i18n/i18n';
import React, {FC, useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {Link} from 'react-router-dom';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {selectOrganizations} from 'state/features/api/organization-slice';
import {selectActiveColumns} from 'state/features/machine-overview-columns/active-column-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {routes} from 'state/routes';
import {alarmColor, warningColor} from 'styles/color-constants';
import {defaultVirtualizedListTableHeight} from 'styles/styling-constants';
import {theme} from 'styles/theme';
import {filterListItems, numericSortWithUndefined, standardValueFormatter} from 'utilities/helpers/general-helpers';
import {FilterConfig} from 'view/components/default-components/filter-panel/filter-panel-types';
import {ColumnDef} from 'view/components/default-components/list-table/list-table-types';
import {VirtualizedListTable} from 'view/components/default-components/list-table/virtual-list-table';
import {MachineConnectionCell} from './machine-connection-cell';
import {MachineLinkStatusCell} from './machine-links-cell';
import {MachineStatusCell} from './machine-status-cell';

type Props = {
  machineFilterConfigs: FilterConfig<IMachineDTO>[];
};

export const MachineOverviewListView: FC<Props> = React.memo((props) => {
  const organizations = useSelector(selectOrganizations);
  const activeColumns = useSelector(selectActiveColumns);

  const mapTankLevel = useCallback((level: TankLevel): string => {
    switch (level) {
      case TankLevel.BelowOk:
        return localized('BelowOK');
      case TankLevel.LevelOk:
        return localized('Ok');
      case TankLevel.MaximumLevel:
        return localized('Maximum');
      case TankLevel.MinimumLevel:
        return localized('Minimum');
    }
  }, []);

  const iconColor = useCallback(
    (machine: IMachineDTO) =>
      machine.activeAlarms?.some((a) => a.alarmSeverity === AlarmSeverity.Alarm) ? alarmColor : warningColor,
    [],
  );

  const showIcon = useCallback(
    (machine: IMachineDTO) =>
      machine.activeAlarms?.length && machine.machineStateDto?.connectionStatus === ConnectionStatus.Online,
    [],
  );

  // ColumnDef inside the component to access organizations
  const columns: ColumnDef<IMachineDTO>[] = useMemo((): ColumnDef<IMachineDTO>[] => {
    const allCols: ColumnDef<
      IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO
    >[] = getAllCols(organizations, showIcon, iconColor, mapTankLevel);
    //Filter out all inactive columns by checking whether they're in the activeColumns array
    return allCols.filter((c) => activeColumns.indexOf(c.field) > -1);
  }, [organizations, activeColumns, mapTankLevel, iconColor, showIcon]);

  const {data: allMachines} = useGetMachinesQuery(undefined, {
    pollingInterval: 10000,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const pageSetting = useSelector(selectPageSettingByKey('machineOverview'));

  const items = useMemo(
    () => filterListItems(allMachines ?? [], ['serialNumber', 'machineTypeName'], pageSetting?.searchString),
    [allMachines, pageSetting],
  );

  return (
    <Box>
      <VirtualizedListTable<IMachineDTO>
        columns={columns}
        rows={items}
        height={defaultVirtualizedListTableHeight}
        searchKeys={['machineTypeId']}
        defaultOrderBy={'serialNumber'}
        filterConfigs={props.machineFilterConfigs}
      />
    </Box>
  );
});
function getAllCols(
  organizations: IOrganizationDTO[],
  showIcon: (machine: IMachineDTO) => boolean | 0 | undefined,
  iconColor: (machine: IMachineDTO) => '#CB4600' | '#FDB900',
  mapTankLevel: (level: TankLevel) => string,
): ColumnDef<IMachineDTO & IMachineLatestCleaningDTO & IMachineLatestStatisticsDTO & IMachineLatestTankStatusDTO>[] {
  return [
    {
      field: 'serialNumber',
      header: localized('SerialNumber'),
      maxWidth: 150,
      valueFormatter: (machine) => `#${machine.serialNumber}`,
    },
    {field: 'machineTypeName', header: localized('MachineType')},
    {
      field: 'organizationId',
      valueFormatter: (machine) => organizations.find((org) => org.id === machine.organizationId)?.name ?? '',
      header: localized('CustomerName'),
    },
    {
      field: 'machineStateDto',
      header: localized('Status'),
      valueFormatter: (machine) => {
        if (
          machine.machineStateDto?.machineStatus === undefined ||
          machine.machineStateDto?.connectionStatus === undefined
        )
          return localized('Unknown');

        return localizedDynamic(machine.machineStateDto.connectionStatus.toString());
      },
      customComponent: (machine) => <MachineStatusCell machine={machine} />,
    },
    {
      field: 'activeAlarms',
      header: localized('ActiveAlarms'),
      maxWidth: 100,
      customComponent: (machine) =>
        showIcon(machine) ? (
          <WarningAmberIcon sx={{position: 'relative', top: 4, fontSize: 40, color: iconColor(machine)}} />
        ) : (
          <Check sx={{position: 'relative', top: 3, fontSize: 40}} />
        ),
    },
    {
      field: 'deviceId',
      header: localized('Connection'),
      valueFormatter: (machine) => machine.machineStateDto?.latestConnectionStateChange?.valueOf().toString() ?? '0',
      customComponent: (machine) => <MachineConnectionCell machine={machine} />,
      minWidth: 250,
    },
    {
      field: 'linkSupport',
      header: localized('Links'),
      sortable: false,
      minWidth: 220,
      maxWidth: 220,
      customComponent: (machine) => <MachineLinkStatusCell machine={machine} />,
    },
    {
      field: 'id',
      header: localized('Details'),
      maxWidth: 70,
      sortable: false,
      customComponent: (machine) => {
        return (
          <Link to={`${routes.machines}/${machine?.id}` ?? ''} style={{textDecoration: 'none'}}>
            <Typography variant="body3" color={theme.colors.sideBar.sidebarSelectedBorder}>
              {localized('Details')}
            </Typography>
          </Link>
        );
      },
    },
    {
      field: 'totalWashes',
      header: localized('TotalWashes'),
      customSort: (a, b) => numericSortWithUndefined(a.latestStatistics?.totalWashes, b.latestStatistics?.totalWashes),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalWashes),
    },
    {
      field: 'washesSinceService',
      header: localized('WashesSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.washesSinceService, b.latestStatistics?.washesSinceService),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.washesSinceService),
    },
    {
      field: 'totalWashHours',
      header: localized('TotalWashHours'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.totalWashHours, b.latestStatistics?.totalWashHours),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalWashHours),
    },
    {
      field: 'washHoursSinceService',
      header: localized('WashHoursSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.washHoursSinceService, b.latestStatistics?.washHoursSinceService),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.washHoursSinceService),
    },
    {
      field: 'totalRinses',
      header: localized('TotalRinses'),
      customSort: (a, b) => numericSortWithUndefined(a.latestStatistics?.totalRinses, b.latestStatistics?.totalRinses),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalRinses),
    },
    {
      field: 'rinsesSinceService',
      header: localized('RinsesSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.rinsesSinceService, b.latestStatistics?.rinsesSinceService),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.rinsesSinceService),
    },
    {
      field: 'totalRinseHours',
      header: localized('TotalRinseHours'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.totalRinseHours, b.latestStatistics?.totalRinseHours),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalRinseHours),
    },
    {
      field: 'rinseHoursSinceService',
      header: localized('RinseHoursSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.rinseHoursSinceService,
          b.latestStatistics?.rinseHoursSinceService,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.rinseHoursSinceService),
    },
    {
      field: 'washesLeftBeforeService',
      header: localized('WashesLeftBeforeService'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.washesLeftBeforeService,
          b.latestStatistics?.washesLeftBeforeService,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.washesLeftBeforeService),
    },
    {
      field: 'washesLeftBeforeFilterChange',
      header: localized('WashesLeftBeforeFilterChange'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.washesLeftBeforeFilterChange,
          b.latestStatistics?.washesLeftBeforeFilterChange,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.washesLeftBeforeFilterChange),
    },
    {
      field: 'totalAniloxes',
      header: localized('TotalAniloxes'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.totalAniloxes, b.latestStatistics?.totalAniloxes),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalAniloxes),
    },
    {
      field: 'aniloxesSinceService',
      header: localized('AniloxesSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.aniloxesSinceService, b.latestStatistics?.aniloxesSinceService),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.aniloxesSinceService),
    },
    {
      field: 'totalLaserHours',
      header: localized('TotalLaserHours'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.totalLaserHours, b.latestStatistics?.totalLaserHours),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalLaserHours),
    },
    {
      field: 'laserHoursSinceService',
      header: localized('LaserHoursSinceService'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.laserHoursSinceService,
          b.latestStatistics?.laserHoursSinceService,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.laserHoursSinceService),
    },
    {
      field: 'totalVacuumHours',
      header: localized('TotalVacuumHours'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestStatistics?.totalVacuumHours, b.latestStatistics?.totalVacuumHours),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.totalVacuumHours),
    },
    {
      field: 'vacuumHoursSinceFilterChange',
      header: localized('VacuumHoursSinceFilterChange'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.vacuumHoursSinceFilterChange,
          b.latestStatistics?.vacuumHoursSinceFilterChange,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.vacuumHoursSinceFilterChange),
    },
    {
      field: 'vacuumHoursUntilNextFilter',
      header: localized('VacuumHoursLeftBeforeFilterChange'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          a.latestStatistics?.vacuumHoursUntilNextFilter,
          b.latestStatistics?.vacuumHoursUntilNextFilter,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestStatistics?.vacuumHoursUntilNextFilter),
    },
    {
      field: 'date',
      header: localized('Date'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.date),
    },
    {
      field: 'time',
      header: localized('Time'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.time),
    },
    {
      field: 'sequence',
      header: localized('Sequence'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.sequence, b.latestCleaning?.sequence),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.sequence),
    },
    {
      field: 'recipe',
      header: localized('Recipe'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.recipe),
    },
    {
      field: 'washTime',
      header: localized('WashTime'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.washTime, b.latestCleaning?.washTime),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.washTime),
    },
    {
      field: 'tank',
      header: localized('Tank'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.tank, b.latestCleaning?.tank),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.tank),
    },
    {
      field: 'temp',
      header: localized('Temperature'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.temp, b.latestCleaning?.temp),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.temp, '°C'),
    },
    {
      field: 'rinse',
      header: localized('Rinse'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.rinse, b.latestCleaning?.rinse),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.rinse),
    },
    {
      field: 'dry',
      header: localized('Dry'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.dry, b.latestCleaning?.dry),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.dry),
    },
    {
      field: 'washSpeed',
      header: localized('WashSpeed'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.washSpeed, b.latestCleaning?.washSpeed),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.washSpeed),
    },
    {
      field: 'employeeId',
      header: localized('EmployeeId'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.employeeId),
    },
    {
      field: 'placeHolder',
      header: 'Placeholder',
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.placeHolder),
    },
    {
      field: 'rollId',
      header: localized('RollId'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.rollId),
    },
    {
      field: 'intensity',
      header: localized('Intensity'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.intensity),
    },
    {
      field: 'color',
      header: localized('Color'),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.color),
    },
    {
      field: 'passes',
      header: localized('Passes'),
      customSort: (a, b) => numericSortWithUndefined(a.latestCleaning?.passes, b.latestCleaning?.passes),
      valueFormatter: (machine) => standardValueFormatter(machine.latestCleaning?.passes),
    },
    {
      field: 'numberOfTanks',
      header: localized('NumberOfTanks'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestTankStatus?.numberOfTanks, b.latestTankStatus?.numberOfTanks),
      valueFormatter: (machine) => standardValueFormatter(machine.latestTankStatus?.numberOfTanks),
    },
    {
      field: 'levelTank1',
      header: localized('LevelTank1'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          b.latestTankStatus?.levelTank1, // Reversed since enum max level is lowest numerical value
          a.latestTankStatus?.levelTank1,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestTankStatus?.levelTank1),
    },
    {
      field: 'temperatureTank1',
      header: localized('TemperatureTank1'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestTankStatus?.temperatureTank1, b.latestTankStatus?.temperatureTank1),
      valueFormatter: (machine) => standardValueFormatter(machine.latestTankStatus?.temperatureTank1, '°C'),
    },
    {
      field: 'levelTank2',
      header: localized('LevelTank2'),
      customSort: (a, b) =>
        numericSortWithUndefined(
          b.latestTankStatus?.levelTank2, // Reversed since enum max level is lowest numerical value
          a.latestTankStatus?.levelTank2,
        ),
      valueFormatter: (machine) => standardValueFormatter(machine.latestTankStatus?.levelTank2),
    },
    {
      field: 'temperatureTank2',
      header: localized('TemperatureTank2'),
      customSort: (a, b) =>
        numericSortWithUndefined(a.latestTankStatus?.temperatureTank2, b.latestTankStatus?.temperatureTank2),
      valueFormatter: (machine) => standardValueFormatter(machine.latestTankStatus?.temperatureTank2, '°C'),
    },
  ];
}
