import React, {FC} from 'react';
import {Box, BoxProps} from '@mui/material';

export const FlexColumn: FC<BoxProps> = React.memo((props) => {
  return (
    <Box display="flex" flexDirection="column" {...props}>
      {props.children}
    </Box>
  );
});
