import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import {SxProps, Theme} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, {FC, useEffect, useState} from 'react';
import {localized} from '../../../../i18n/i18n';
import {useRequired} from '../required/required-provider';
import {CssTextField} from '../text-fields/basic-text-field';
interface PropsFromParent {
  onChange: (item: DropdownOption) => void;
  items: DropdownOption[];
  label: string;
  value: DropdownOption | null | undefined;
  required?: boolean;
  requiredText?: string;
  isInvalid?: boolean;
  sx?: SxProps<Theme>;
}

const dropdownSx: SxProps = {display: 'contents'};

export class DropdownOption {
  label: string;
  value: any;
  constructor(label: string, value: any) {
    this.label = label;
    this.value = value;
  }
}

const shrink = {
  shrink: true,
};

export const BasicSearchDropdown: FC<PropsFromParent> = React.memo(
  ({onChange, items, label, value, requiredText, required, isInvalid, sx}) => {
    const {showError} = useRequired();
    const showRequired = showError && required && ((isInvalid === undefined && !value) || isInvalid);

    const renderInput = (params: any) => (
      <CssTextField
        disabled={true}
        helperText={showRequired ? requiredText : ' '}
        error={showRequired}
        {...params}
        label={label}
        margin="normal"
        InputLabelProps={shrink}
      />
    );
    const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

    // Add an item option in case of
    useEffect(() => {
      let options = items;

      setDropdownOptions(options);
    }, [items]);

    const onChangeLocal = (event: any, newValue: any) => {
      onChange(newValue);
    };

    const getOptionLabel = (option: DropdownOption) => {
      return option && option.label;
    };

    const getOptionSelected = (option: DropdownOption, valueOption: DropdownOption) =>
      option && valueOption && option.value === valueOption.value;

    // If the value doesn't exist, is has to be null, for typescript not to deem it as an uncontrolled error
    return (
      <Autocomplete
        sx={{...dropdownSx, ...sx}}
        value={value ? value : null}
        onChange={onChangeLocal}
        options={dropdownOptions}
        id="disable-clearable"
        getOptionLabel={getOptionLabel}
        isOptionEqualToValue={getOptionSelected}
        renderInput={renderInput}
        popupIcon={<KeyboardArrowDown />}
      />
    );
  },
);

BasicSearchDropdown.defaultProps = {
  requiredText: localized('FieldIsRequired'),
  required: false,
};
