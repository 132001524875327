import {Theme} from '@mui/material';
import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import React from 'react';
import {FC} from 'react';
import {localized} from '../i18n/i18n';
import {BasicButton} from '../view/components/default-components/buttons/basic-button';
import {useAuth} from './auth-provider';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      heigth: '100%',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
    title: {
      fontSize: 50,
    },
    subTitle: {
      fontSize: 30,
      margin: 10,
    },
  }),
);

export const NoPermissionPage: FC = () => {
  const {logout} = useAuth();
  const signOut = () => logout();
  const classes = useStyles();

  return (
    <div className={classes.container} onClick={signOut}>
      <div className={classes.title}>{localized('NoPermission')}</div>
      {localized('NoPermissionSubTitle')
        .split('\n')
        .map((text, index) => (
          <div key={index} className={classes.subTitle}>
            {text}
          </div>
        ))}

      <BasicButton text={localized('LogOut')} onClick={signOut} />
    </div>
  );
};
