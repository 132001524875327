import {SvgIconProps} from '@mui/material/SvgIcon';
import {MachineStatus} from 'api/api';
import {Translation} from 'i18n/i18n';
import React from 'react';
import {PauseIcon} from 'view/components/default-components/icons/pause-icon/pause-icon';
import {RoundedCrossIcon} from 'view/components/default-components/icons/rounded-cross-icon/rounded-cross-icon';
import {StandbyIcon} from 'view/components/default-components/icons/standby-icon/standby-icon';
import WashingIcon from 'view/components/default-components/icons/washing-icon';

type MachineStateProperties = {
  icon: React.FC<SvgIconProps>;
  stateTranslationKey: keyof Translation;
};

type MachineStateMapperType = Record<MachineStatus | 'Unknown', MachineStateProperties>;

export const MachineStateMapper: MachineStateMapperType = {
  [MachineStatus.Running]: {
    stateTranslationKey: 'Running',
    icon: WashingIcon,
  },
  [MachineStatus.Standby]: {
    stateTranslationKey: 'Standby',
    icon: StandbyIcon,
  },
  [MachineStatus.Paused]: {
    stateTranslationKey: 'MachinePaused',
    icon: PauseIcon,
  },
  Unknown: {
    stateTranslationKey: 'WashingUnknown',
    icon: RoundedCrossIcon,
  },
};
