import {Icon, InputAdornment, TextField, TextFieldProps, Theme} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import React, {FC, useCallback} from 'react';
import {MdIcons} from '../../../../utilities/material-design-icons';
import {useRequired} from '../required/required-provider';
import {localized} from '../../../../i18n/i18n';

interface PropsFromParent {
  label?: string;
  suffix?: string;
  suffixIcon?: MdIcons;
  prefixIcon?: MdIcons;
  prefix?: string;
  text?: string;
  textChanged?: (newText: string) => void;
  placeholder?: string;
  required?: boolean;
  requiredText?: string;
  disabled?: boolean;
  isInvalid?: boolean;
}

export const CssTextField = withStyles((theme: Theme) => ({
  root: {
    margin: theme.spacing(1),
    maxWidth: '300px',
    width: '100%',

    '& label.Mui-focused': {
      color: theme.colors.textField.textFieldLabelColor,
    },
    '& label': {
      color: theme.colors.textField.textFieldLabelColor,
    },
    '& input': {
      color: theme.colors.textField.textFieldInputColor,
    },
    '& .MuiInput-underline:after': {
      borderBottomColor: theme.colors.textField.textFieldLabelColor,
    },
  },
}))(TextField);

type Props = PropsFromParent & TextFieldProps;

const shrink = {
  shrink: true,
};

export const BasicTextField: FC<Props> = React.memo(
  ({
    textChanged,
    label,
    prefix,
    suffix,
    text,
    suffixIcon,
    prefixIcon,
    placeholder,
    requiredText,
    required,
    disabled,
    isInvalid, // If undefined, text field requirement is if empty.
    ...props
  }) => {
    const {showError} = useRequired();

    const textChangedLocal = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (textChanged) {
          textChanged(event.target.value);
        }
      },
      [textChanged],
    );

    const showRequired = showError && required && ((isInvalid === undefined && !text) || isInvalid);

    return (
      <CssTextField
        disabled={disabled}
        {...props}
        InputLabelProps={shrink}
        helperText={showRequired ? requiredText : ' '}
        error={showRequired}
        label={label}
        onChange={textChangedLocal}
        value={text ? text : ''}
        placeholder={placeholder}
        InputProps={{
          startAdornment:
            prefix || prefixIcon ? (
              <InputAdornment position="start">{prefix ? prefix : <Icon>{prefixIcon}</Icon>}</InputAdornment>
            ) : null,
          endAdornment:
            suffix || suffixIcon ? (
              <InputAdornment position="end">{suffix ? suffix : <Icon>{suffixIcon}</Icon>}</InputAdornment>
            ) : null,
        }}
      />
    );
  },
);

BasicTextField.defaultProps = {
  requiredText: localized('FieldIsRequired'),
  required: false,
};
