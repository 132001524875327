import {Box, Typography} from '@mui/material';
import {ConnectionStatus, IMachineDTO, MachineStatus} from 'api/api';
import {useAuth} from 'auth/auth-provider';
import {localized, localizedInterpolation} from 'i18n/i18n';
import React, {useCallback, useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {useGetMachinesQuery} from 'state/features/api/machines-slice';
import {selectOrganizations, useGetOrganizationsQuery} from 'state/features/api/organization-slice';
import {selectCurrentUser} from 'state/features/api/user-slice';
import {selectPageSettingByKey} from 'state/features/page-header-setting/page-header-setting-slice';
import {ViewType} from 'state/features/page-header-setting/types';
import {routes} from 'state/routes';
import {BasicButton} from 'view/components/default-components/buttons/basic-button';
import {FilterConfig} from 'view/components/default-components/filter-panel/filter-panel-types';
import {FlexRow} from 'view/components/default-components/flex-row';
import {PageHeader} from 'view/components/default-components/page-header.tsx/page-header';
import {PageHeaderFilter} from 'view/components/default-components/page-header.tsx/page-header-filter';
import {PageHeaderSearch} from 'view/components/default-components/page-header.tsx/page-header-search';
import {PageHeaderToggleView} from 'view/components/default-components/page-header.tsx/page-header-toggle-view';
import {BasicSpinner} from 'view/components/default-components/spinner/basic-spinner';
import LanguagePicker from 'view/components/specific-components/language-picker';
import {MachinesBoxView} from 'view/components/specific-components/machines-box-view/machines-box-view';
import {PageHeaderColumnSelector} from 'view/pages/machines/overview/column-selector';
import {theme} from '../../../styles/theme';
import {PageContentContainer} from '../../components/default-components/page-container/page-content-container';
import {PageTitle} from '../../components/default-components/page-title/page-title';
import {DownloadExcelButton} from './overview/download-excel-button';
import {MachineOverviewListView} from './overview/machine-overview-list/machine-overview-list-view';
export const MachineOverview = React.memo(() => {
  const {isGlobalAdmin, isAgent} = useAuth();
  const navigate = useNavigate();
  const {isLoading: showSpinner} = useGetMachinesQuery();
  const pageSetting = useSelector(selectPageSettingByKey('machineOverview'));
  const currentUser = useSelector(selectCurrentUser);
  const organizations = useSelector(selectOrganizations);

  // FilterConfig inside the component to access organizations
  const machineFilterConfigs: FilterConfig<IMachineDTO>[] = useMemo(
    () => [
      {
        field: 'machineTypeName',
        header: localized('MachineType'),
      },
      {
        field: 'machineStateDto',
        header: localized('Status'),
        valueFormatter: (machine) => {
          if (machine.machineStateDto?.connectionStatus !== ConnectionStatus.Online) return localized('Unknown');
          switch (machine.machineStateDto?.machineStatus) {
            case MachineStatus.Running:
              return localized('Running');
            case MachineStatus.Paused:
              return localized('Paused');
            case MachineStatus.Standby:
              return localized('Standby');
            default:
              return localized('Unknown');
          }
        },
        options: [localized('Running'), localized('Paused'), localized('Standby'), localized('Unknown')],
      },
      {
        field: 'organizationId',
        valueFormatter: (machine) => organizations.find((org) => org.id === machine.organizationId)?.name ?? '',
        header: localized('CustomerName'),
      },
      {
        field: 'deviceId',
        header: localized('Connection'),
        valueFormatter: (machine) => {
          return machine.machineStateDto?.connectionStatus !== ConnectionStatus.Online
            ? localized('Offline')
            : localized('Online');
        },
      },
    ],
    [organizations],
  );

  useGetOrganizationsQuery(undefined, {skip: !isGlobalAdmin && !isAgent});
  const {data: machines} = useGetMachinesQuery(undefined, {
    pollingInterval: 10000,
    refetchOnReconnect: true,
    refetchOnFocus: true,
    refetchOnMountOrArgChange: true,
  });
  const goToCreateMachine = useCallback(() => {
    navigate(routes.machinesCreate);
  }, [navigate]);

  const goToAddMachine = useCallback(() => {
    navigate(routes.machinesAdd);
  }, [navigate]);

  return (
    <PageTitle title={localized('MachineOverview')}>
      <PageHeader area="machineOverview">
        <Box
          sx={{
            display: 'flex',
            columnGap: 5,
            alignItems: 'center',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            marginBottom: theme.spacing(2),
          }}>
          <Typography variant="h6" color="primary.main">
            {localized('MachineOverview')}
          </Typography>
          <LanguagePicker />
        </Box>
        <Box sx={{display: 'flex', justifyContent: isGlobalAdmin ? 'space-between' : 'flex-end', alignItems: 'center'}}>
          {(isGlobalAdmin || !!currentUser?.agentId) && (
            <FlexRow>
              <PageHeaderSearch />
              <PageHeaderToggleView />
              <PageHeaderFilter filterConfigs={machineFilterConfigs} data={machines ?? []} />
              <PageHeaderColumnSelector />
              <DownloadExcelButton />
            </FlexRow>
          )}

          {isGlobalAdmin && (
            <BasicButton
              sx={{minWidth: 200}}
              onClick={goToCreateMachine}
              text={localizedInterpolation('CreateNewInt', 'Machine')}
            />
          )}

          {!isGlobalAdmin && !isAgent && (
            <BasicButton sx={{minWidth: 200}} onClick={goToAddMachine} text={'Add machine'} />
          )}
        </Box>
      </PageHeader>
      {showSpinner ? (
        <BasicSpinner />
      ) : !pageSetting || pageSetting.itemView === ViewType.BoxView ? (
        <MachinesBoxView />
      ) : pageSetting.itemView === ViewType.ListView ? (
        <PageContentContainer xy>
          <MachineOverviewListView machineFilterConfigs={machineFilterConfigs} />
        </PageContentContainer>
      ) : null}
    </PageTitle>
  );
});
