import {SvgIconProps} from '@mui/material/SvgIcon';
import Typography, {TypographyProps} from '@mui/material/Typography';
import {Theme} from '@mui/system/createTheme';
import {SxProps} from '@mui/system/styleFunctionSx';
import {ConnectionStatus} from 'api/api';
import {localized} from 'i18n/i18n';
import React from 'react';
import {MachineConnectionMapper} from 'utilities/mappers/machine-connection-mapper';
import {getFormattedDate, showTimestamp} from 'utilities/platform-helpers/date-helpers';
import IconLabelPlaceholder from 'view/components/specific-components/icon-label-placeholder';
import {IconLabelPlaceholderProps} from 'view/components/specific-components/icon-label-placeholder/icon-label-placeholder';

type Variant = 'machine' | 'machine-details';

const iconStyles: SxProps<Theme> = {color: 'common.flexoWashBlueLight', fontSize: '2.5rem'};

const variantMapper: Record<Variant, TypographyProps> = {
  machine: {
    fontSize: '1rem',
  },
  'machine-details': {
    fontSize: '1.25rem',
  },
};

interface Props extends IconLabelPlaceholderProps {
  connectionState: ConnectionStatus;
  latestHeartbeat?: Date;
  variant?: Variant;

  iconProps?: SvgIconProps;
}

export const MachineConnection: React.FC<Props> = (props) => {
  const {
    children,
    connectionState,
    latestHeartbeat,
    variant = 'machine',
    iconPlacement = 'right',
    iconProps,
    ...rest
  } = props;

  const {stateTranslationKey: connectionStatus, icon: ConnectionIcon, color} = MachineConnectionMapper[connectionState];

  return (
    <IconLabelPlaceholder
      iconPlacement={iconPlacement}
      icon={<ConnectionIcon sx={iconStyles} {...iconProps} />}
      {...rest}>
      <Typography variant="body3" color={color} textTransform="uppercase" fontWeight={500} {...variantMapper[variant]}>
        {connectionStatus}
      </Typography>
      <Typography variant="body3" color="common.flexoWashBlueLight">
        {latestHeartbeat === undefined ? (
          <>{localized('MachineNoActivity')}</>
        ) : (
          <>
            {connectionState !== ConnectionStatus.Online
              ? `${localized('Since')} ${getFormattedDate(latestHeartbeat)}`
              : localized('MachineActive')}
          </>
        )}
      </Typography>
      {variant == 'machine-details' && latestHeartbeat && (
        <Typography variant="body3" color="common.flexoWashBlueLight">
          {localized('LastHeartbeat')} {showTimestamp(latestHeartbeat)}
        </Typography>
      )}
    </IconLabelPlaceholder>
  );
};

export default MachineConnection;
