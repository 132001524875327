import {SvgIconProps} from '@mui/material/SvgIcon';
import {ConnectionStatus} from 'api/api';
import {Translation} from 'i18n/i18n';
import React from 'react';
import {theme} from 'styles/theme';
import OfflineIcon from 'view/components/default-components/icons/offline-icon';
import {OnlineIcon} from 'view/components/default-components/icons/online-icon/online-icon';

type MachineConnectionProperties = {
  icon: React.FC<SvgIconProps>;
  stateTranslationKey: keyof Translation;
  color: string;
};

type ConnectionMapperType = Record<ConnectionStatus, MachineConnectionProperties>;

export const MachineConnectionMapper: ConnectionMapperType = {
  [ConnectionStatus.Online]: {
    stateTranslationKey: 'Online',
    icon: OnlineIcon,
    color: theme.palette.common.flexowashGreen,
  },
  [ConnectionStatus.Offline]: {
    stateTranslationKey: 'Offline',
    icon: OfflineIcon,
    color: theme.palette.common.flexowashRed,
  },
  [ConnectionStatus.Unknown]: {
    stateTranslationKey: 'Offline',
    icon: OfflineIcon,
    color: theme.palette.common.flexowashRed,
  },
};
