import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {WebappState} from './types';

const initialState: WebappState = {
  open: false,
  showOverlaySpinner: false,
};

export const webappSlice = createSlice({
  name: 'webappSettings',
  initialState,
  reducers: {
    toggleDrawer: (state) => {
      state.open = !state.open;
    },
    setOverlaySpinner: (state, action: PayloadAction<boolean>) => {
      state.showOverlaySpinner = action.payload;
    },
  },
});

export const {toggleDrawer, setOverlaySpinner} = webappSlice.actions;
export default webappSlice.reducer;
